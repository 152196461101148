import { Button } from 'react-bootstrap'

export default function MyButton(props: {
	size?: 'sm' | 'lg'
	className?: any
	style?: object
	children: any
	href?: string
}) {
	let style: object
	if (props.style) {
		style = props.style
	} else {
		style = {
			boxShadow: 'none',
		}
	}

	let href = '/estimate'
	if (props.href) {
		href = props.href
	}

	return (
		<Button
			size={props.size}
			className={`myButton ${props.className}`}
			style={style}
			href='/estimate'
		>
			{props.children}
		</Button>
	)
}
