import { Image } from 'react-bootstrap'

export default function Logo(props: {
	size: string
	className?: any
	style: any
	children?: any
}) {
	let src = 'logo'
	if (props.style) {
		src = props.style
	}

	return (
		<Image
			alt='logo'
			src={`/images/logos/${src}.png`}
			width={props.size}
			height={props.size}
			className={`d-inline-block align-top ${props.className}`}
		/>
	)
}
