import HomeComponent from 'components/page-specific/HomeComponent'
import ButtonFooter from 'components/page-specific/ButtonFooter'
import 'css/HomePage.css'

function Home() {
	return (
		<>
			<ButtonFooter />
			<HomeComponent />
		</>
	)
}

export default Home
