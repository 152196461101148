import 'Global.css'
import { Col, Container } from 'react-bootstrap'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from 'pages/HomePage'
import About from 'pages/AboutPage'
import ContactUs from 'pages/ContactUsPage'
import { MessageProvider } from 'contexts/MessageProvider'
import EstimatePage from 'pages/EstimatePage'
import { EstimateProvider } from 'contexts/EstimateProvider'
import SchedulePage from 'pages/SchedulePage'
import NotFound from 'pages/NotFound'
import Layout from 'components/layout/Layout'

function App() {
	return (
		<MessageProvider>
			<link
				href='https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
				rel='stylesheet'
			></link>
			<EstimateProvider>
				<Layout>
					<Container className='containerBody'>
						<Col
							sm={2}
							md={3}
							lg={4}
						></Col>
						<Col>
							<div className='m-5 bg-color-tertiary color-black'>
								<BrowserRouter>
									<Routes>
										<Route
											path='/'
											element={<Home />}
										/>
										<Route
											path='/about-us'
											element={<About />}
										/>
										<Route
											path='/contact-us'
											element={<ContactUs />}
										/>
										<Route
											path='/estimate'
											element={<EstimatePage />}
										/>
										<Route
											path='/schedule'
											element={<SchedulePage />}
										/>
										<Route
											path='*'
											element={<NotFound />}
										/>
									</Routes>
								</BrowserRouter>
							</div>
						</Col>
						<Col
							sm={2}
							md={3}
							lg={4}
						></Col>
					</Container>
				</Layout>
			</EstimateProvider>
		</MessageProvider>
	)
}

export default App
