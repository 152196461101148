import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import EstimateContext from 'contexts/EstimateContext'
import { IEstimate } from 'types/estimate'
import ReCAPTCHA from 'react-google-recaptcha'
import 'css/EstimateForm.css'
import { Check2 } from 'react-bootstrap-icons'
import React from 'react'
import { companyEmail, companyPhone } from 'environmentVariableTypes'

function EstimateForm() {
	// hooks
	const [modalShow, setModalShow] = React.useState(false)
	const [valid, setValid] = useState(true)
	const [notValidEmail, setNotValidEmail] = useState(true)
	const [bot, setBot] = useState<boolean>(false)
	const [botModalShow, setBotModalShow] = React.useState(false)

	let {
		createEstimate,
		email,
		setEmail,
		seats,
		setSeats,
		leather,
		setLeather,
		vehicleType,
		setVehicleType,
		headlights,
		setHeadlights,
		pets,
		setPets,
		smoke,
		setSmoke,
		price,
		setPrice,
	} = useContext(EstimateContext)

	const navigate = useNavigate()

	// --------Pricing----------
	useEffect(() => {
		let newEstimatePrice

		switch (vehicleType) {
			case 'auto':
				newEstimatePrice = 150
				if (seats < 5) setSeats(5)
				if (seats > 5) {
					newEstimatePrice += (seats - 5) * 5
				}
				if (headlights) {
					newEstimatePrice += 65
				}
				if (leather) {
					newEstimatePrice += 10
				}
				if (pets) {
					newEstimatePrice += 10
				}
				if (smoke) {
					newEstimatePrice += 10
				}
				setPrice(newEstimatePrice)

				break
			case 'headlights':
				newEstimatePrice = 80
				setPrice(newEstimatePrice)

				break
			case 'plane':
				newEstimatePrice = 130
				if (seats > 2) {
					newEstimatePrice += (seats - 2) * 20
				}
				if (leather) {
					newEstimatePrice += 10
				}
				if (pets) {
					newEstimatePrice += 10
				}
				if (smoke) {
					newEstimatePrice += 10
				}
				setPrice(newEstimatePrice)

				break

			default:
				break
		}
	}, [seats, headlights, leather, vehicleType, pets, smoke, setPrice, setSeats])
	// --------Pricing----------

	// reCAPTCHA
	const reCAPTCHAKey: string = '6LdVAGUnAAAAAAOejCq1K_ei5Gof8dIWtuA0foKI'
	const recaptchaRef = useRef<ReCAPTCHA>(null)

	function validateEmail(email: string) {
		var re = /\S+@\S+\.\S+/
		return re.test(email)
	}

	function MessageSent(props: any) {
		return (
			<Modal
				{...props}
				size='md'
				centered
				backdrop='static'
				keyboard={false}
			>
				<Modal.Header
					style={{
						justifyContent: 'center',
						display: 'flex',
						textAlign: 'center',
					}}
					onClick={() => setValid(true)}
				>
					<Modal.Title>
						Your Estimate has been Locked In!
						<h6>Check your email for more info!</h6>
					</Modal.Title>
				</Modal.Header>
				<Modal.Footer style={{ justifyContent: 'center', display: 'flex' }}>
					<Button onClick={() => navigate('/schedule')}>Schedule!</Button>
				</Modal.Footer>
			</Modal>
		)
	}

	// function BotMessage(props: any) {
	// 	return (
	// 		<Modal
	// 			{...props}
	// 			size='md'
	// 			centered
	// 			backdrop='static'
	// 			keyboard={false}
	// 		>
	// 			<Modal.Header
	// 				closeButton
	// 				onClick={() => setBot(false)}
	// 			>
	// 				<Modal.Title>
	// 					Message Failed!
	// 					<h6>
	// 						reCAPTCHA thinks you are a bot. If you are a human, please call or
	// 						email us.
	// 					</h6>
	// 					<p>{companyPhone}</p>
	// 					<p>{companyEmail}</p>
	// 					<p>We apologize for the inconvenience.</p>
	// 				</Modal.Title>
	// 			</Modal.Header>
	// 		</Modal>
	// 	)
	// }

	async function handleSubmit(event: { preventDefault: () => void }) {
		event.preventDefault()

		// reCAPTCHA
		let reCaptchaToken = await recaptchaRef.current?.executeAsync()
		recaptchaRef.current?.reset()
		if (!reCaptchaToken) {
			reCaptchaToken = 'no token'
		}
		localStorage.setItem('reCAPTCHAToken', reCaptchaToken)

		setModalShow(true)
		setNotValidEmail(true)

		let newEstimate: IEstimate = {
			email,
			seats,
			leather,
			// conditioner,
			price,
			pets,
			smoke,
		}

		createEstimate(newEstimate)
			.then(() => {
				// reCAPTCHA
				localStorage.setItem('reCAPTCHAToken', '')
				// setValid(false)
			})
			.catch((error: any) => {
				console.log(error)
				if (error.response.status === 403) {
					setBot(true)
					console.log(
						'we think you are a bot. If you are a human, please call or email us.'
					)
				} else if (email === '' || validateEmail(email) === false) {
					setNotValidEmail(false)
				}
			})

		navigate('/schedule')
	}

	return (
		<div>
			<Container>
				<Row>
					<Col
						sm='12'
						md='7'
						xl='8'
					>
						<Card className='cardEstimate'>
							<h1 className='formTitle'>Interior Estimate</h1>
							<div className='mb-3'>
								<Form.Label>Type of vehicle</Form.Label>
								<Form.Check
									type='radio'
									label='Auto'
									name='auto'
									checked={vehicleType === 'auto'}
									onChange={(e) => {
										setVehicleType(e.target.name)
									}}
									// onChange={(e) => console.log(e.target.name)}
									as='input'
								/>

								<Form.Check
									type='radio'
									label='Headlights only'
									name='headlights'
									checked={vehicleType === 'headlights'}
									onChange={(e) => {
										setVehicleType(e.target.name)
									}}
									// onChange={(e) => console.log(e.target.name)}
									as='input'
								/>

								{/* <Form.Check
									type='radio'
									label='Airplane'
									name='plane'
									checked={vehicleType === 'plane'}
									onChange={(e) => {
										setVehicleType(e.target.name)
										// if (vehicleType !== 'equipment') {
										setSeats(2)
										// }
									}}
									// onChange={(e) => console.log(e.target.name)}
									as='input'
								/>
 */}
								<Form.Check
									type='radio'
									label='Heavy Equipment'
									name='equipment'
									checked={vehicleType === 'equipment'}
									onChange={(e) => setVehicleType(e.target.name)}
									// onChange={(e) => console.log(e.target.name)}
									as='input'
								/>
							</div>
							<Form.Label
								className={
									vehicleType === 'equipment' || vehicleType === 'headlights'
										? 'color-tertiary'
										: ''
								}
							>
								Number of Seats
							</Form.Label>
							<Form.Range
								disabled={
									vehicleType === 'equipment' || vehicleType === 'headlights'
								}
								name='seats'
								value={seats}
								onChange={(e) => setSeats(parseInt(e.target.value))}
								min={vehicleType === 'plane' ? '2' : '5'}
								max='13'
							/>
							<Form.Label
								className={
									vehicleType === 'equipment' || vehicleType === 'headlights'
										? 'color-tertiary'
										: ''
								}
							>
								{seats}
								{seats === 2 && vehicleType === 'plane' && ' or less'}
								{seats === 5 && vehicleType !== 'plane' && ' or less'}
								{seats === 13 && ' or more'}
							</Form.Label>

							<Form.Check
								disabled={
									vehicleType === 'equipment' || vehicleType === 'headlights'
								}
								type='switch'
								label='Do you want your headlights cleaned?'
								name='headlights'
								checked={headlights}
								onChange={(e) => setHeadlights(e.target.checked)}
								as='input'
							/>

							<Form.Check
								disabled={
									vehicleType === 'equipment' || vehicleType === 'headlights'
								}
								type='switch'
								label='Does you vehicle have Leather Seats?'
								name='leather'
								checked={leather}
								onChange={(e) => setLeather(e.target.checked)}
								as='input'
							/>

							{/* <Form.Check
								disabled={!leather}
								type="switch"
								label="Do you want your Leather Seats Conditioned?"
								name="conditioner"
								checked={conditioner}
								onChange={(e) => setConditioner(e.target.checked)}
								as="input"
							/> */}

							<Form.Check
								disabled={
									vehicleType === 'equipment' || vehicleType === 'headlights'
								}
								type='switch'
								label='Are pets regularly in the vehicle?'
								name='pets'
								checked={pets}
								onChange={(e) => setPets(e.target.checked)}
								as='input'
							/>

							<Form.Check
								disabled={
									vehicleType === 'equipment' || vehicleType === 'headlights'
								}
								type='switch'
								label='Do you smoke in the vehicle?'
								name='smoke'
								checked={smoke}
								onChange={(e) => setSmoke(e.target.checked)}
								as='input'
							/>
							{smoke && (
								<p
									className={
										vehicleType === 'equipment' || vehicleType === 'headlights'
											? 'color-tertiary'
											: ''
									}
								>
									Due to the pervasiveness of smoke, we may not get all of the
									smoke smell out of your vehicle.
								</p>
							)}
						</Card>
					</Col>

					<Col
						sm='12'
						md='5'
						xl='4'
					>
						<Card className='formEstimate'>
							<div className='heading1'>
								<strong>
									{vehicleType === 'equipment' || seats == 13 ? (
										<h4>Contact Us for an Estimate</h4>
									) : (
										<h4>TOTAL INTERIOR ESTIMATE: ${price}</h4>
									)}
								</strong>
							</div>
							{/* {vehicleType === 'headlights' && (
								<div className='cardAndExpire'>
									<p>
										Headlights{' '}
										<Check2
											color='green'
											size={18}
										/>
									</p>
								</div>
							)} */}
							{vehicleType !== 'equipment' ? (
								<>
									{vehicleType === 'headlights' ? (
										<div
											className='cardAndExpire'
											style={{ marginBottom: '-30px' }}
										>
											<p>
												Headlights{' '}
												<Check2
													color='green'
													size={18}
												/>
											</p>
										</div>
									) : (
										<>
											<div
												className='cardAndExpire'
												style={{ marginTop: '1px' }}
											>
												<p>
													{seats} Seats
													{seats === 2 && vehicleType === 'plane' && ' or less'}
													{seats === 5 && vehicleType !== 'plane' && ' or less'}
													{seats === 13 && ' or more'}
												</p>
											</div>
											<div className='cardAndExpire'>
												{headlights && (
													<p>
														Headlights{' '}
														<Check2
															color='green'
															size={18}
														/>
													</p>
												)}
											</div>
											<div className='cardAndExpire'>
												{leather && (
													<p>
														Leather{' '}
														<Check2
															color='green'
															size={18}
														/>
													</p>
												)}
											</div>
											<div className='cardAndExpire'>
												{pets && (
													<p>
														Pets{' '}
														<Check2
															color='green'
															size={18}
														/>
													</p>
												)}
											</div>
											<div
												className='cardAndExpire'
												style={{ marginBottom: '-30px' }}
											>
												{smoke === true && (
													<p>
														Smoke{' '}
														<Check2
															color='green'
															size={18}
														/>
													</p>
												)}
											</div>
										</>
									)}
									<div className='emailEstimate'>
										<p className='bodyEstimate'>
											<strong>LOCK IN YOUR ESTIMATE</strong>
										</p>
										<Form className='review'>
											<Form.Label>
												Enter your Email to lock in your estimate for 90 days
												and we will reach out to book an appointment.
											</Form.Label>
											<Form.Control
												placeholder='Enter email'
												type='text'
												name='email'
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
											{(() => {
												if (notValidEmail === false) {
													return (
														<Form.Label className='requiredEstimate'>
															Please Enter a Valid Message
														</Form.Label>
													)
												}
											})()}
										</Form>

										<br />
									</div>
									<Link
										className=''
										onClick={handleSubmit}
										style={{ textDecoration: 'none' }}
										to={''}
									>
										<div className='cardFooter text-center bg-color-primary'>
											LOCK IN ESTIMATE
										</div>
									</Link>
								</>
							) : (
								<>
									<div className='emailEstimate'>
										<p className='bodyEstimate'>
											<strong>GET YOUR ESTIMATE</strong>
										</p>
										<Form className='review'>
											<Form.Label>
												Enter your Email and we will reach out and give you an
												estimate.
											</Form.Label>
											<Form.Control
												placeholder='Enter email'
												type='text'
												name='email'
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
											{(() => {
												if (notValidEmail === false) {
													return (
														<Form.Label className='requiredEstimate'>
															Please Enter a Valid Message
														</Form.Label>
													)
												}
											})()}
										</Form>

										<br />
									</div>
									<Link
										className=''
										onClick={handleSubmit}
										style={{ textDecoration: 'none' }}
										to={''}
									>
										<div className='cardFooter text-center bg-color-primary'>
											LOCK IN ESTIMATE
										</div>
									</Link>
								</>
							)}
							{/* <div className='emailEstimate'>
								<p className='bodyEstimate'>
									<strong>LOCK IN YOUR ESTIMATE</strong>
								</p>
								<Form className='review'>
									<Form.Label>
										Enter your Email to lock in your estimate for 90 days and we
										will reach out to book an appointment.
									</Form.Label>
									<Form.Control
										placeholder='Enter email'
										type='text'
										name='email'
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
									{(() => {
										if (notValidEmail === false) {
											return (
												<Form.Label className='requiredEstimate'>
													Please Enter a Valid Message
												</Form.Label>
											)
										}
									})()}
								</Form>

								<br />
							</div>
							<Link
								className=''
								onClick={handleSubmit}
								style={{ textDecoration: 'none' }}
								to={''}
							>
								<div className='cardFooter text-center bg-color-primary'>
									LOCK IN ESTIMATE
								</div>
							</Link> */}
						</Card>
					</Col>
					{/* <div className='captchaStyle'>
						<ReCAPTCHA
							sitekey={reCAPTCHAKey}
							size='invisible'
							ref={recaptchaRef}
							className='grecaptcha-badge'
						/>
					</div> */}
				</Row>
				{(() => {
					if (valid === false && validateEmail(email) === true) {
						return (
							<MessageSent
								show={modalShow}
								onHide={() => {
									setModalShow(false)
								}}
							/>
						)
					}
				})()}
				{/* {(() => {
					if (bot) {
						return (
							<BotMessage
								show={botModalShow}
								onHide={() => setBotModalShow(false)}
							/>
						)
					}
				})()} */}
			</Container>
		</div>
	)
}

export default EstimateForm
