// import { Button } from 'react-bootstrap'
import 'css/ButtonFooter.css'
import MyButton from 'components/reuseable/MyButton'

function ButtonFooter() {
	return (
		<div className='buttonFooter'>
			<MyButton
				style={{
					position: 'absolute',
					right: '-40px',
					bottom: '-80px',
				}}
				size='lg'
				className='me-4 buttonShape d-none d-xxl-block d-xxxl-block bg-color-secondary border-color-secondary color-white'
				href='/estimate'
			>
				Get a <strong>FREE</strong> Estimate Now
			</MyButton>
		</div>
	)
}

export default ButtonFooter
