import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Col, Container, Row } from 'react-bootstrap'
import 'Global.css'
import BottomBar from 'components/page-specific/BottomBar'
import Logo from 'components/reuseable/Logo'
import MyButton from 'components/reuseable/MyButton'

export default function Footer() {
	return (
		<>
			<Navbar
				variant='dark'
				className='color-tertiary bg-color-primary'
			>
				<Container>
					<Row className='center-content m-auto'>
						<Col
							xs='12'
							md='7'
							lg='5'
							className='center-content my-auto'
						>
							<Navbar.Brand
								className='ms-4 color-tertiary'
								href='/'
							>
								<Row className='center-content'>
									<Col
										className='center-content'
										xs='12'
										sm='auto'
										md='4'
									>
										<Logo
											size='100'
											style={'d-c-white-logo'}
										/>
									</Col>
									<Col
										className='center-content'
										xs='12'
										md={{ span: 7, offset: 1 }}
									>
										<div className='center'>
											<h3>Hays Mobile Detailing</h3>
											We come to you near
											<br />
											Hays, KS
										</div>
									</Col>
								</Row>
							</Navbar.Brand>
						</Col>

						<Col
							xs='12'
							md='3'
							lg='5'
							className='justify-content-center center my-auto'
						>
							<MyButton
								size='lg'
								className=' myButton'
								style={{
									boxShadow: 'none',
									backgroundColor: '#3888CB',
									borderColor: '#3888CB',
								}}
							>
								Get a <strong>FREE</strong> Estimate Now
							</MyButton>
						</Col>

						<Col
							md='2'
							lg='2'
							className='center-content d-none d-md-block my-auto'
						>
							<Nav className='me-4 flex-column'>
								<Nav.Link
									className='nowrap'
									href='/'
								>
									Home
								</Nav.Link>
								<Nav.Link
									className='nowrap'
									href='/about-us'
								>
									About Us
								</Nav.Link>
								<Nav.Link
									className='nowrap'
									href='/contact-us'
								>
									Contact Us
								</Nav.Link>
							</Nav>
						</Col>
					</Row>
				</Container>
			</Navbar>
			<BottomBar />
		</>
	)
}
