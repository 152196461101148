import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Col, Container } from 'react-bootstrap'
import Logo from 'components/reuseable/Logo'
import MyButton from 'components/reuseable/MyButton'

export default function Header() {
	return (
		<Navbar
			variant='dark'
			sticky='top'
			expand='lg'
			className='color-white bg-color-primary'
		>
			<Container>
				<Col
					xs='2'
					lg='auto'
				>
					<Navbar.Brand
						href='/'
						className='color-tertiary'
					>
						<Logo
							size={'35'}
							style={'simple-white-logo'}
						/>
						Hays Mobile{' '}
						<span className='d-none d-md-inline-block'> Detailing</span>
					</Navbar.Brand>
				</Col>
				<Col
					xs='4'
					lg='auto'
					className='ms-5'
				>
					<MyButton>
						<span className='d-none d-md-inline-block'> Get a </span>{' '}
						<strong>FREE</strong> Estimate{' '}
						<span className='d-none d-md-inline-block'> Now</span>
					</MyButton>
				</Col>

				<Col xs='auto'>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav>
							<>
								<Nav.Link href='/'>Home</Nav.Link>
								<Nav.Link href='/about-us'>About</Nav.Link>
								<Nav.Link href='/contact-us'>Contact Us</Nav.Link>
							</>
						</Nav>
					</Navbar.Collapse>
				</Col>
			</Container>
		</Navbar>
	)
}
