import Navbar from 'react-bootstrap/Navbar'
import 'Global.css'
import { Container, Col } from 'react-bootstrap'

export default function BottomBar() {
	return (
		<Navbar className='bg-color-secondary color-white'>
			<Container>
				<Col>
					<p className='center'>
						This site was created by
						<a
							className='hidden-links px-1 nowrap'
							href='https://www.linkedin.com/in/mike-misiura/'
							target='blank'
						>
							Mike Misiura
						</a>
					</p>
				</Col>
			</Container>
		</Navbar>
	)
}
