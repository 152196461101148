// import Calendly from "components/page-specific/Calendly";

function SchedulePage() {
	return (
		<>
			{/* <Calendly /> */}
			<h4>Congrats!</h4>
			<p>Your estimate has been sent!</p>
			<p>We will reach out to schedule an appointment soon.</p>
		</>
	)
}

export default SchedulePage
