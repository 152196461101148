// import Calendly from "components/page-specific/Calendly";
import EstimateForm from 'components/page-specific/EstimateForm'

function EstimatePage() {
	return (
		<>
			<EstimateForm />

			{/* <Calendly /> */}
		</>
	)
}

export default EstimatePage
