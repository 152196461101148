import ContactForm from 'components/page-specific/ContactForm'

function ContactUs() {
	return (
		<div>
			<ContactForm />
		</div>
	)
}

export default ContactUs
